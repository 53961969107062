import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, any, string, Container } from "shards-react";
import 'react-activity/dist/react-activity.css';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { ButtonGroup, Checkbox, FormControlLabel } from '@material-ui/core';
import PropagateLoader from "react-spinners/ClipLoader";
import Firebase from 'firebase';
import uuid from 'react-uuid';


class RoomDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bookings: new Date(),
            selected: [],
            daysSelected: false,
            services: [],
            dates: [],
            stock: 0,
            error: false,
            price: 0,
            options: [],
            alreadyExistDates: [],
            allDates: [],
            desc: '',
            facilities: [],
            images: [],
            resourceId: 0,
            refresh: true,
            nightSet: false,
            disabledDates: [

            ], minDates: [],
            eventDb: [],
            tempDate: [],
            tempEvent: new Date(),
            totalNights: 0,
            numberBooking: 0,
            loading: true,

        };
    }

    checkRoomStock() {
        console.log(this.state.dates)



    }



    getDatesBetweenDates = (startDate, endDate) => {
        let dates = []
        //to avoid modifying the original date
        const theDate = new Date(startDate)
        while (theDate < endDate) {
            dates = [...dates, new Date(theDate)]
            theDate.setDate(theDate.getDate() + 1)
        }
        dates = [...dates, endDate]
        return dates
    }


    generateBookingId() {
        let lengthIs = 0;

        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();

        return 'B' + year + month + this.state.numberBooking
    }

    fetchOtherData() {

        let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/events');

        ref.on('value', snapshot => {
            this.setState({
                eventDb: []
            })
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();
                this.setState(prevState => ({
                    eventDb: [...prevState.eventDb, state]
                }))

            })
            this.setState({
                numberBooking: (parseInt(this.state.eventDb.length) + 1)
            })
        });

        for (let i = 0; i < this.state.services.length; i++) {

            if (this.props.data.roomName.name === this.state.services[i].name) {
                /*let dates = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/'+this.state.services[i].id+'/dates');
                dates.on('value' , snapshot => {
                    snapshot.forEach((childSnap) => {

                    let state = childSnap.val();
                    console.log(state)
                        
                  });
                })*/



                localStorage.setItem('serviceId', this.state.services[i].id)
                console.log('match')
                this.setState({
                    roomData: this.state.services[i],
                    stock: this.state.services[i].stock,
                    desc: this.state.services[i].desc,
                    resourceId: this.state.services[i].resourceId,
                })



                let roomItem = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/dates');
                roomItem.on('value', snapshot => {

                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();
                        console.log("aantal kamers is:" + this.state.services[i].name)
                        console.log("aantal kamers is:" + this.props.data.roomName.name)
                        console.log("aantal kamers is:" + state.amount)
                        console.log("aantal kamers is:" + (this.state.stock - 1))
                        console.log("aantal kamers is:" + (new Date(state.year, state.month, state.day)))

                        if (state.amount > this.state.stock - 1) {


                            this.setState(prevState => ({
                                disabledDates: [...prevState.disabledDates, new Date(state.year, (state.month), state.day)],
                            }));

                        }


                    });
                    this.setState(prevState => ({
                        disabledDates: [...prevState.disabledDates, new Date(2022, 11, 23)]
                    }))
                    this.setState(prevState => ({
                        disabledDates: [...prevState.disabledDates, new Date(2022, 11, 24)]
                    }))
                    this.setState(prevState => ({
                        disabledDates: [...prevState.disabledDates, new Date(2023, 11, 25)]
                    }))
                    this.setState(prevState => ({
                        disabledDates: [...prevState.disabledDates, new Date(2023, 11, 26)]
                    }))

                    this.setState(prevState => ({
                        disabledDates: [...prevState.disabledDates, new Date(2023, 11, 27)]
                    }))
                    this.setState(prevState => ({
                        disabledDates: [...prevState.disabledDates, new Date(2023, 11, 28)]
                    }))
                    this.setState(prevState => ({
                        disabledDates: [...prevState.disabledDates, new Date(2023, 11, 29)]
                    }))
                    this.setState(prevState => ({
                        disabledDates: [...prevState.disabledDates, new Date(2023, 11, 30)]
                    }))
                    this.setState(prevState => ({
                        disabledDates: [...prevState.disabledDates, new Date(2023, 11, 31)]
                    }))

                    console.log(this.state.disabledDates)

                })

                let minDates = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/minDates');
                minDates.on('value', snapshot => {

                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();
                        let newMinDates = { date: new Date(state.year, state.month, state.day), amount: state.amount }
                        this.setState(prevState => ({
                            minDates: [...prevState.minDates, newMinDates],
                        }));

                    });

                })

                let prices = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/prices');
                prices.on('value', snapshot => {
                    this.setState({ options: [] })
                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();
                        this.setState(prevState => ({
                            options: [...prevState.options, state],
                        }));
                    });
                })

                let facilities = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/facilities');
                facilities.on('value', snapshot => {
                    this.setState({ facilities: [] })
                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();
                        this.setState(prevState => ({
                            facilities: [...prevState.facilities, state],
                        }));
                    });
                })

                let images = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services/' + this.state.services[i].id + '/images');
                images.on('value', snapshot => {
                    this.setState({ images: [] })
                    snapshot.forEach((childSnap) => {
                        let state = childSnap.val();
                        this.setState(prevState => ({
                            images: [...prevState.images, state],
                        }));
                    });
                })


            } else {
                console.log('unmatch')
            }
        }


    }

    fetchData() {

        let fetchedItems = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services');
        fetchedItems.on('value', snapshot => {

            snapshot.forEach((childSnap) => {

                let state = childSnap.val();
                this.setState(prevState => ({
                    services: [...prevState.services, state],
                }));




            });
            this.fetchOtherData();






        })


    }

    setReady() {
        this.setState({
            loading: false
        }

        )
    }



    componentWillMount = async () => {
        window.scrollTo(0, 0)

        setTimeout(() => {
            this.fetchData();
            console.log('hello timeout')
        }, 1000);



        setTimeout(() => { this.setReady(); }, 3000);










    }

    onFocus = () => {

        if (this.state.refresh === true) {
            console.log('true new')
        }

    }


    checkDisable(arr, event) {
        console.log(event[0])
        for (let i = 0; i < arr.length; i++) {
            for (let k = 0; k < this.state.disabledDates.length; k++) {
                if (arr[i].getDay() === this.state.disabledDates[k].getDay() && arr[i].getMonth() === this.state.disabledDates[k].getMonth()) {
                    console.log('double date');
                    this.setState({
                        errorMessage: 'er is een datum geselect die reeds bezet is, selecteer een andere datum.'
                    });
                    console.error('dezde datum is al bezet')
                } else {
                    console.log('nothing');
                    this.setState({
                        selected: event
                    })
                    this.calculateNights(event[0], event[1]);
                }
            }
        }
    }




    dateRange(startDate, endDate, steps = 1) {
        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            dateArray.push(new Date(currentDate));
            // Use UTC date to prevent problems with time zones and DST
            currentDate.setUTCDate(currentDate.getUTCDate() + steps);
        }

        return dateArray;
    }



    onchangeValue(event, back) {

        this.setState({
            errorMessage: ''
        })

        localStorage.setItem('bookingID', this.generateBookingId())


        let dates = this.dateRange(event[0], event[1]);


        console.log()
        //this.checkDisable(arr,prevEvent);

        for (let i = 0; i < dates.length; i++) {
            this.calculateNights(event[0], event[1])
            for (let k = 0; k < this.state.disabledDates.length; k++) {

                if (dates[i].getDate() === this.state.disabledDates[k].getDate() && dates[i].getMonth() === this.state.disabledDates[k].getMonth() && dates[i].getFullYear() === this.state.disabledDates[k].getFullYear()) {
                    console.log('double date' + dates[i].getDate());
                    this.setState({
                        errorMessage: 'Er is een datum geselect die reeds bezet is, selecteer een andere datum.',
                        error: true
                    });
                    console.error('dezde datum is al bezet');
                    return
                }
                else {
                    this.setState({
                        selected: event, error: false, nightSet: true
                    })
                    this.calculateNights(event[0], event[1]);
                }
            }

            for (let j = 0; j < this.state.minDates.length; j++) {
                console.log("new nights are" + this.calculateNightsMinData(event[0], event[1]))

                if ((event[0].getDay() === 5 || event[0].getDay() === 6) && this.calculateNightsMinData(event[0], event[1]) < 2) {
                    this.setState({
                        errorMessage: 'Je moet op de' + this.state.minDates[j].date.getDate() + '-ste minimaal' + this.state.minDates[j].amount + ' nachten boeken',
                        error: true
                    });
                    console.error('dezde datum is al bezet');
                    return
                }
                else {
                    this.setState({
                        selected: event, error: false, nightSet: true
                    })
                    // this.calculateNights(event[0],event[1]);
                }
            }
        }



        /**/
    }

    startdayCustom() {
        return new Date(2021, 1, 8);
    }

    calculateNights(start, stop) {
        var date1 = new Date(start);
        var date2 = new Date(stop);

        // Zet datums om naar UTC
        date1.setUTCHours(0, 0, 0, 0);
        date2.setUTCHours(0, 0, 0, 0);

        var timeDiff = Math.abs(date2.getTime() - date1.getTime());
        var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24) - 1);
        this.setState({
            totalNights: numberOfNights
        });
    }


    calculateNightsMinData(start, stop) {
        var date1 = new Date(start);
        var date2 = new Date(stop);
        var timeDiff = Math.abs(date2.getTime() - date1.getTime());
        var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24) - 1);
        return numberOfNights

    }


    addDays = (date, days = 1) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    dateRangeBetween = (start, end, range = []) => {
        if (start > end) return range;
        const next = this.addDays(start, 1);
        return this.dateRange(next, end, [...range, start]);
    };



    CalculatePriceDifference(month, day, price) {
        console.log(month)
        console.log(day)
        if (month == 6 && day == 4 || month == 6 && day == 5 || month == 6 && day == 6 || month == 6 && day == 7 || month == 6 && day == 11 || month == 6 && day == 12 || month == 6 && day == 13 || month == 6 && day == 14 || month == 6 && day == 18 || month == 6 && day == 19 || month == 6 && day == 20 || month == 6 && day == 21) {

            let NewPrice = Number(price) + 10;

            return NewPrice;

        }
        else {
            let NewPrice = Number(price);

            return NewPrice;
        }

    }


    calculatePrice(item) {



        for (let i = 0; i < this.state.options.length; i++) {
            if (item === this.state.options[i].id) {

                let PriceHigherSummer = this.CalculatePriceDifference(this.state.selected[0].getMonth(), this.state.selected[0].getDate(), this.state.options[i].price);


                this.setState({
                    price: PriceHigherSummer
                })

                localStorage.setItem('roomdataTitle', this.props.data.roomName.name);
                localStorage.setItem('roomdataId', this.props.data.roomName.id);
                localStorage.setItem('roomdataPrice', PriceHigherSummer);
                localStorage.setItem('guests', this.state.options[i].person);
                localStorage.setItem('resourceId', this.state.resourceId);
                localStorage.setItem('fullStartDate', this.state.selected[0]);
                localStorage.setItem('fullEndDate', this.state.selected[1]);
                localStorage.setItem('serviceId', this.props.data.roomName.id);
                localStorage.setItem('totalNights', this.state.totalNights);

                localStorage.setItem('startYear', this.state.selected[0].getFullYear());
                localStorage.setItem('startMonth', this.state.selected[0].getMonth());
                localStorage.setItem('startDay', this.state.selected[0].getDate())
                localStorage.setItem('endYear', this.state.selected[1].getFullYear());
                localStorage.setItem('endMonth', this.state.selected[1].getMonth());
                localStorage.setItem('endDay', this.state.selected[1].getDate())



                if (this.state.totalNights > 0 && this.state.error === false) {
                    this.setState({
                        daysSelected: true
                    })
                }


            }
        }
    }

    changeActivePicture(image) {
        console.log(image)

        for (let i = 0; i < this.state.images.length; i++) {
            if (image.id === this.state.images[i].id) {
                image.type = 'active';
                this.setState(prevState => ({
                    images: [...prevState.images, image],
                }));

            }


        }

    }


    isSunday = date => {
        return date.getDay() === 0; // 0 staat voor zondag
    };


    render() {


        return (
            this.state.loading ?
                <Container>
                    <div className="detail-page">
                        <Row className="spinner-row">
                            <Col sm={12} className="spinner-col">
                                <PropagateLoader />
                            </Col>

                            <Col sm={12} className="spinner-col-title">
                                <h3>Kamer details worden geladen</h3>
                            </Col>
                        </Row>
                    </div>
                </Container>
                :
                <Container>
                    <div className="detail-page">

                        <Row>
                            <Col sm={6} className="highlighted-picture">
                                {this.state.images.filter(image => image.type == 'active').slice(-1).map((image) => (
                                    <img src={image.url} />
                                ))}
                            </Col>
                            <Col sm={6} className="collected-pictures">
                                {this.state.images.slice(1, 5).map((image) => (
                                    <img src={image.url} onClick={() => this.changeActivePicture(image)} />
                                ))}
                            </Col>
                        </Row>

                        <Row className="detail-row">
                            <Col sm={8} className="room-details">
                                <h3>{this.props.data.roomName.name}</h3>
                                <span className="main-details">
                                    <p className="amount-people "><img alt='Het logo van Picoo' src={require('../../assets/images/icons/person-icon.png')} />Max. {this.state.options.slice(-1).map((item, i) => (item.person))} Personen</p>
                                    <p className="border-right border-left"> {this.state.facilities.slice(0, 1).map((item, i) => (item.name))}</p>
                                    <p>{this.state.facilities.slice(1, 2).map((item, i) => (item.name))}</p>
                                </span>
                                <hr />

                                <ul>
                                    {this.state.facilities.map((item, i) => (
                                        <li><i class="fas fa-check-circle"></i> {item.name}</li>
                                    ))}
                                </ul>
                                <hr />


                                <p>
                                    <b>{this.state.desc}</b>
                                </p>
                                <p>
                                    Onze B&B is minder toegankelijk voor mindervalide gasten. <br /><br />
                                    Het ontbijt kan je nuttigen in onze sfeervolle verblijfsruimtes. Bij goed weer kan je ook gebruik maken van ons terras. In de avonduren staan de verblijfsruimtes ter beschikking voor het nuttigen van een drankje en een borrelhapje. Ook een kopje koffie of thee maken we graag voor je. Het ontbijt is op werkdagen van 7.00 uur tot 09.00 uur. In het weekend van 8.00 uur tot 10.00 uur. Andere tijden zijn op aanvraag mogelijk.

                                </p>
                            </Col>
                            <Col sm={4} className="room-pricing-details">
                                <h3>Beschikbaarheid</h3>
                                <Calendar locale={"nl-NL"} value={[this.state.selected[0], this.state.selected[1]]} selectRange={true} returnValue={"range"} onChange={(event) => this.onchangeValue(event, event)} minDate={new Date()} tileDisabled={({ date, view }) =>
                                    (view === 'month') && // Block day tiles only
                                    this.state.disabledDates.some(disabledDate =>
                                        date.getFullYear() === disabledDate.getFullYear() &&
                                        date.getMonth() === disabledDate.getMonth() &&
                                        date.getDate() === disabledDate.getDate()
                                    )} />
                                {this.state.error ?
                                    <span className="error-message-room">{this.state.errorMessage}</span>
                                    : null}
                                {this.state.nightSet ? <span>
                                    <label>Aantal personen</label>
                                    <ButtonGroup variant="text" color="#27718F" aria-label="text primary button group" className="select-buttons" >
                                        {this.state.options.sort((a, b) => (a.price > b.price) ? 1 : -1).map((item, i) => (
                                            <Button value={item.id} onClick={(event) => this.calculatePrice(event.target.value)}>{item.person}</Button>
                                        ))}

                                    </ButtonGroup>
                                </span> : null}
                                {/*
                    <label>Extra's</label>
                    <span className="checkbox-extras">
                        <FormControlLabel control={<Checkbox name="checkedC" />} label="Ontbijt" />
                        <FormControlLabel control={<Checkbox name="checkedC" />} label="Avondeten" />
                        <FormControlLabel control={<Checkbox name="checkedC" />} label="Ontbijt" />
                        <FormControlLabel control={<Checkbox name="checkedC" />} label="Avondeten" />
</span>*/}

                                <hr />
                                {this.state.error ?
                                    null
                                    :
                                    this.state.daysSelected ?
                                        <span className="total-price-room-details">
                                            <p className="total-price"><b>Aantal nachten:</b></p> <p className="calculated-price">{this.state.totalNights}</p>
                                            <p className="total-price"><b>Aantal personen:</b></p> <p className="calculated-price">{localStorage.getItem('guests')}</p>
                                            <p className="total-price"><b>Totaal:</b></p> <p className="calculated-price">€ {parseFloat(this.state.price * this.state.totalNights).toFixed(2)}</p>
                                        </span>
                                        : null}

                                {this.state.error ?
                                    null
                                    :
                                    this.state.daysSelected ?
                                        <Link className="room-price-button" to={{ pathname: `/online-afrekenen`, state: { roomdata: this.props.data.roomName, totalNights: this.state.totalNights } }} >Boeken</Link>

                                        :
                                        <div className="no-dates-selected">
                                            <p>Selecteer datums</p>
                                        </div>
                                }
                            </Col>

                        </Row>



                    </div>
                </Container>

        );
    }
};

export default RoomDetailComponent;
