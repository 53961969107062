import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, any, string, Container } from "shards-react";
import 'react-activity/dist/react-activity.css';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { ButtonGroup, Checkbox, FormControlLabel } from '@material-ui/core';
import PropagateLoader from "react-spinners/ClipLoader";
import Firebase from 'firebase';
import uuid from 'react-uuid';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { DateUtils } from 'react-day-picker';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

class RoomDetailFamily extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startdate: '',
      startDay: '',
      startMonth: '',
      startYear: '',
      selectedDay: undefined,
      enddate: '',
      endDay: '',
      endMonth: '',
      endYear: '',
      fridays: [],
      mondays: [],
      monday: false,
      uitcheckdag: '',
      totalNights: 0,
      amountPersons: 4,
      price: 0,
      defaultPrice: 0,
      disabledDays: [],
      showCalendar: false,
      numberBooking: 0,
      eventDb: [],
      selectedType: '',
      mondayMidweek: false,
      mondayWeek: false,
      friday: false,
      fridayWeek: false,
      images: [
        { url: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/biejanssen%2FFamiliekamer%202%20personen-min-min.jpg?alt=media&token=32fa99d7-3b9a-4899-bb49-ca2058f84af5", id: 0, type: 'active' },
        { url: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/biejanssen%2FFamiliekamer%201%20persoon%202-min-min.jpg?alt=media&token=ec5df4ae-7d3b-4538-9101-d08b336a2dc3", id: 1, type: 'not-active' },
        { url: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/biejanssen%2FFamiliekamer%201%20persoon%201-min-min.jpg?alt=media&token=c2b700de-8740-424a-860d-66652fc691d2", id: 2, type: 'not-active' },
        { url: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/biejanssen%2FFamiliekamer%20woonkamer%201-min-min.jpg?alt=media&token=79e3268f-c32b-47a8-85e2-4d8b6afe1722", id: 3, type: 'not-active' },
        { url: "https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/biejanssen%2FFamiliekamer%20woonkamer%202-min-min.jpg?alt=media&token=6ef7374c-9e68-463c-97ee-ad6ad025bc2f", id: 4, type: 'not-active' }
      ]

    };
    this.handleDayChange = this.handleDayChange.bind(this);
  }





  checkDateBefore(date, days) {
    var f, newDate;

    f = [];
    if (days > 0) {
      if (date.getDay() != 4) {
        f.push(new Date(date));
      }
      newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 1);
      console.log(newDate)
      return f.concat(this.getFridays(newDate, days - 1));
    }
    return f;
  }

  changeActivePicture(image) {
    console.log(image)

    for (let i = 0; i < this.state.images.length; i++) {
      if (image.id === this.state.images[i].id) {
        image.type = 'active';
        this.setState(prevState => ({
          images: [...prevState.images, image],
        }));

      }


    }

  }



  componentWillMount = async () => {
    let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/events');

    ref.on('value', snapshot => {
      this.setState({
        eventDb: []
      })
      snapshot.forEach((childSnap) => {
        let state = childSnap.val();
        this.setState(prevState => ({
          eventDb: [...prevState.eventDb, state]
        }))

      })
      this.setState({
        numberBooking: (parseInt(this.state.eventDb.length) + 1)
      })
    });

    window.scrollTo(0, 0)

    let fetchedItems = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/specialServices/-iedklnedwbedpw/dates/');
    fetchedItems.on('value', snapshot => {

      snapshot.forEach((childSnap) => {

        let state = childSnap.val();
        this.setState(prevState => ({
          disabledDays: [...prevState.disabledDays, state],
        }));

        console.log(state);
        console.log(new Date(state.year, state.month, state.day, 1, 0, 0, 0))
        this.setState(prevState => ({
          fridays: [...prevState.fridays, new Date(state.year, state.month, state.day, 1, 0, 0, 0)],
          mondays: [...prevState.mondays, new Date(state.year, state.month, state.day, 1, 0, 0, 0)]
        }))


      });

    })

    this.setState(prevState => ({
      fridays: [...prevState.fridays, new Date()],
      mondays: [...prevState.mondays, new Date()]
    }))

  }


  handleDayChange(day) {
    this.setState({ selectedDay: day });

    this.setState({
      startdate: day,
      startDay: new Date(day).getDate(),
      startMonth: new Date(day).getMonth(),
      startYear: new Date(day).getFullYear()
    })
  }

  handleDayChangeEnd(day) {
    this.setState({ selectedDay: day });

    this.setState({
      enddate: day,
      endDay: new Date(day).getDate(),
      endMonth: new Date(day).getMonth(),
      endYear: new Date(day).getFullYear()
    })

    console.log(day)
  }

  parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }
  getFridays(date, days) {
    var f, newDate;

    f = [];
    if (days > 0) {
      if (date.getDay() != 5) {
        f.push(new Date(date));
      }
      newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 1);
      return f.concat(this.getFridays(newDate, days - 1));
    }
    return f;
  }



  getMondays(date, days) {
    var f, newDate;

    f = [];
    if (days > 0) {
      if (date.getDay() != 1) {
        f.push(new Date(date));
      }
      newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 1);
      return f.concat(this.getMondays(newDate, days - 1));
    }
    return f;
  }

  disableFridays() {

    let disabledDays = this.getFridays(new Date(), 400)

    for (let i = 0; i < disabledDays.length; i++) {
      this.setState(prevState => ({
        fridays: [...prevState.fridays, disabledDays[i]],
        monday: false,
        showCalendar: true
      }))
    }



  }

  disableMondays() {
    let disabledDays = this.getMondays(new Date(), 400)
    for (let i = 0; i < disabledDays.length; i++) {
      this.setState(prevState => ({
        mondays: [...prevState.mondays, disabledDays[i]],
        monday: true,
        showCalendar: true
      }))
    }
  }

  generateBookingId() {
    let lengthIs = 0;

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();

    return 'B' + year + month + this.state.numberBooking
  }



  changeType(event) {
    console.log(event)
    localStorage.setItem('bookingID', this.generateBookingId())
    localStorage.setItem('roomdataTitle', 'Familie kamer');
    localStorage.setItem('roomdataId', '-iwkbenw');

    localStorage.setItem('guests', this.state.amountPersons);
    localStorage.setItem('resourceId', 4);
    this.changeAmountPeople(4)

    if (event === 'Vrijdag-weekend' || event === 'Vrijdag-week') {

      this.disableFridays();

      if (event === 'Vrijdag-weekend') {
        this.setState({
          price: 449,
          defaultPrice: 449,
          selectedType: "Vrijdag-weekend",
          uitcheckdag: 'Maandag',
          totalNights: 3,
          friday: true,
          mondayWeek: false,
          mondayMidweek: false,
          fridayWeek: false,
        })
        localStorage.setItem('totalNights', 3);
        localStorage.setItem('roomdataPrice', 449);
      } else if (event === 'Vrijdag-week') {
        console.log('week')
        this.setState({
          price: 799,
          defaultPrice: 799,
          uitcheckdag: 'Vrijdag',
          selectedType: "Vrijdag-week",
          totalNights: 7,
          friday: false,
          mondayWeek: false,
          mondayMidweek: false,
          fridayWeek: true,
        })
        localStorage.setItem('totalNights', 7);
        localStorage.setItem('roomdataPrice', 799);
      }


    } else if (event === 'Maandag-midweek' || event === 'Maandag-week') {
      this.disableMondays();
      if (event === 'Maandag-midweek') {
        console.log('midweek')
        this.setState({
          price: 599,
          defaultPrice: 599,
          uitcheckdag: 'Vrijdag',
          selectedType: "Maandag-midweek",
          totalNights: 4,
          friday: false,
          mondayWeek: false,
          mondayMidweek: true,
          fridayWeek: false,
        })
        localStorage.setItem('totalNights', 4);
        localStorage.setItem('roomdataPrice', 599);
      } else if (event === 'Maandag-week') {
        console.log('week')
        this.setState({
          price: 799,
          defaultPrice: 799,
          uitcheckdag: 'Maandag',
          selectedType: "Maandag-week",
          totalNights: 7,
          friday: false,
          mondayWeek: true,
          mondayMidweek: false,
          fridayWeek: false,
        })
        localStorage.setItem('totalNights', 7);
        localStorage.setItem('roomdataPrice', 799);
      }
    }
  }

  handleDayClick(day) {


    // Check if the selectedType is 'Vrijdag-weekend' or 'Vrijdag-week' and disable all other days except Fridays
    if (this.state.selectedType === 'Vrijdag-weekend' || this.state.selectedType === 'Vrijdag-week') {
      if (day.getDay() !== 5) { // Friday has index 5 in JavaScript Date object
        return; // Do nothing if the selected day is not Friday
      }
    }
    // Check if the selectedType is 'Maandag-midweek' or 'Maandag-week' and disable all other days except Mondays
    else if (this.state.selectedType === 'Maandag-midweek' || this.state.selectedType === 'Maandag-week') {
      if (day.getDay() !== 1) { // Monday has index 1 in JavaScript Date object
        return; // Do nothing if the selected day is not Monday
      }
    }
    this.setState({
      selectedDay: day,
    });

    if (this.state.selectedType === 'Maandag-midweek') {
      let calcLastDay = day.getDate() + 4;



      localStorage.setItem('fullStartDate', day);
      localStorage.setItem('fullEndDate', new Date(day.getFullYear(), day.getMonth(), calcLastDay));
      localStorage.setItem('startYear', day.getFullYear());
      localStorage.setItem('startMonth', day.getMonth());
      localStorage.setItem('startDay', day.getDate())
      localStorage.setItem('endYear', day.getFullYear());
      localStorage.setItem('endMonth', day.getMonth());
      localStorage.setItem('endDay', calcLastDay)

    } else if (this.state.selectedType === 'Maandag-week') {
      let calcLastDay = day.getDate() + 7;



      localStorage.setItem('fullStartDate', day);
      localStorage.setItem('fullEndDate', new Date(day.getFullYear(), day.getMonth(), calcLastDay));
      localStorage.setItem('startYear', day.getFullYear());
      localStorage.setItem('startMonth', day.getMonth());
      localStorage.setItem('startDay', day.getDate())
      localStorage.setItem('endYear', day.getFullYear());
      localStorage.setItem('endMonth', day.getMonth());
      localStorage.setItem('endDay', calcLastDay)

    } else if (this.state.selectedType === 'Vrijdag-weekend') {
      let calcLastDay = day.getDate() + 3;



      localStorage.setItem('fullStartDate', day);
      localStorage.setItem('fullEndDate', new Date(day.getFullYear(), day.getMonth(), calcLastDay));
      localStorage.setItem('startYear', day.getFullYear());
      localStorage.setItem('startMonth', day.getMonth());
      localStorage.setItem('startDay', day.getDate())
      localStorage.setItem('endYear', day.getFullYear());
      localStorage.setItem('endMonth', day.getMonth());
      localStorage.setItem('endDay', calcLastDay)

    }

    else if (this.state.selectedType === 'Vrijdag-week') {
      let calcLastDay = day.getDate() + 3;



      localStorage.setItem('fullStartDate', day);
      localStorage.setItem('fullEndDate', new Date(day.getFullYear(), day.getMonth(), calcLastDay));
      localStorage.setItem('startYear', day.getFullYear());
      localStorage.setItem('startMonth', day.getMonth());
      localStorage.setItem('startDay', day.getDate())
      localStorage.setItem('endYear', day.getFullYear());
      localStorage.setItem('endMonth', day.getMonth());
      localStorage.setItem('endDay', calcLastDay)

    }


  }

  changeAmountPeople(value) {
    this.setState({
      amountPersons: parseInt(value, 10),
      price: this.state.defaultPrice + (parseInt(value, 10) - 4) * 10 * this.state.totalNights
    }, () => {
      localStorage.setItem('guests', this.state.amountPersons);
      localStorage.setItem('roomdataPrice', this.state.price);
    });
  }


  render() {
    const today = new Date();
    const disabledDays = [
      { before: today },
      this.state.monday ? this.state.mondays : this.state.fridays
    ];
    const FORMAT = 'dd/MM/yyyy';
    return (
      this.state.loading ?
        <Container>
          <div className="detail-page">
            <Row className="spinner-row">
              <Col sm={12} className="spinner-col">
                <PropagateLoader />
              </Col>

              <Col sm={12} className="spinner-col-title">
                <h3>Kamer details worden geladen</h3>
              </Col>
            </Row>
          </div>
        </Container>
        :
        <Container>
          <div className="detail-page">

            <Row>
              <Col sm={6} className="highlighted-picture">
                {this.state.images.filter(image => image.type == 'active').slice(-1).map((image) => (
                  <img src={image.url} />
                ))}
              </Col>
              <Col sm={6} className="collected-pictures">
                {this.state.images.slice(1, 5).map((image) => (
                  <img src={image.url} onClick={() => this.changeActivePicture(image)} />
                ))}
              </Col>
            </Row>

            <Row className="detail-row">
              <Col sm={8} className="room-details">
                <h3>Familiekamer</h3>



                <p>

                  Op de 3 e  etage is de gezellige familiekamer gelegen, waar je met 4-6 personen kan overnachten. De
                  familiekamer bestaat uit een kleine gezellige woonkamer, 3 slaapkamers, een badkamer met douche,
                  wastafel en toilet en op de gang ligt nog een separaat toilet.<br /><br />
                  <b>3 slaapkamers</b><br />
                  1 grote 2-persoonskamer met boxspring bed van 1,6 bij 2 meter, wastafel en opbergkast
                  2 keer een 1-persoonskamer met Ikea Hemnes bed, welk uit is te schuiven, zodat er nog een 2	e
                  persoon op de kamer kan slapen. Beide kamers hebben ook een eigen wastafel.<br /><br />
                  <b>Woonkamer</b><br />
                  De kleine woonkamer beschikt over een bankstel, TV, uitklapbare tafel, een koffie- en theehoekje en
                  een klein ijskastje. Er staat een waterkoker en servies. Wij voorzien jullie in koffie, thee, melkcupjes
                  en suiker.<br /><br />
                  <b>Badkamer</b><br />
                  Naast een separaat toilet ligt op de gang ook de badkamer voorzien van een douche, wastafel en WC.<br /><br />
                  <b>Boeken</b><br />
                  De familiekamer is te boeken voor een (lang) weekend, midweek of week. Weekend is van vrijdag 15
                  uur tot maandag 10 uur. Midweek is van maandag 15 uur tot vrijdag 10 uur en een week is van
                  vrijdag 15 uur tot vrijdag 10 uur.
                  Prijs is € 449 voor een weekend (3 nachten), € 599 voor een midweek (4 nachten) en € 799 voor een
                  week (7 nachten). Prijs is op basis van 4 personen inclusief ontbijt. Iedere extra persoon betaald € 10
                  per nacht.  Schoonmaakkosten € 40. Toeristenbelasting is € 2,25 per persoon per nacht.
                </p>
              </Col>
              <Col sm={4} className="room-pricing-details">
                <h3>Beschikbaarheid</h3>
                <label>Type boeking</label>
                <span className="choice-family">
                  <input type="checkbox" onClick={() => this.changeType('Maandag-midweek')} id="scales" name="scales" checked={this.state.mondayMidweek} /><label for="scales">Maandag - midweek (4 - nachten)</label>
                </span>
                <span className="choice-family">
                  <input type="checkbox" onClick={() => this.changeType('Maandag-week')} id="scales" name="scales" checked={this.state.mondayWeek} /><label for="scales">Maandag - hele week (7 - nachten)</label>
                </span>
                <span className="choice-family">
                  <input type="checkbox" onClick={() => this.changeType('Vrijdag-weekend')} id="scales" name="scales" checked={this.state.friday} /><label for="scales">Vrijdag - weekend (3 - nachten)</label>
                </span>
                <span className="choice-family">
                  <input type="checkbox" onClick={() => this.changeType('Vrijdag-week')} id="scales" name="scales" checked={this.state.fridayWeek} /><label for="scales">Vrijdag - hele week (7 - nachten)</label>
                </span>
                {/*}
                          <select onChange={(event)=> this.changeType(event.target.value)}>
                            <option selected="selected" disabled="disabled">Selecteer een variant</option>
                            <option value="Maandag-midweek">Maandag - midweek</option> 
                            <option value="Maandag-week">Maandag - hele week</option>
                            <option value="Vrijdag-weekend">Vrijdag - Weekend</option>
                    </select>*/}
                {this.state.showCalendar ?
                  <span> <label>Aankomst datum</label>
                    <DayPicker
                      selectedDays={this.state.selectedDay}
                      onDayClick={(event) => this.handleDayClick(event)}
                      formatDate={this.formatDate}
                      format={FORMAT}
                      parseDate={this.parseDate}
                      disabledDays={disabledDays}
                      modifiersStyles={{ disabled: { opacity: '0.3' } }}
                      onDayChange={(event) => this.handleDayChangeEnd(event)}
                    />


                    <span className="total-price-room-details">
                      <p className="total-price"><b>Aantal nachten:</b></p> <p className="calculated-price">{this.state.totalNights}</p>
                      <p className="total-price"><b>Uitchecken op:</b></p> <p className="calculated-price">{this.state.uitcheckdag}</p>
                      <p className="total-price"><b>Aantal personen:</b></p> <p className="calculated-price"> <select value={this.state.amountPersons.toString()} onChange={(event) => this.changeAmountPeople(event.target.value)}><option value="4">4</option> <option value="5">5</option><option value="6">6</option></select></p>
                      <p className="total-price"><b>Totaal:</b></p> <p className="calculated-price">€ {parseFloat(this.state.price).toFixed(2)}</p>
                    </span>
                    <Link className="room-price-button" to={{ pathname: `/online-afrekenen-familie`, state: { roomdata: 'familie-kamer', totalNights: this.state.totalNights } }} >Boeken</Link>
                  </span>

                  : null}

              </Col>

            </Row>



          </div>
        </Container>

    );
  }
};

export default RoomDetailFamily;
